.compare-empty {

    .first-step {
        display: inline-block;
        vertical-align: top;
    }

    .secund-step {
        display: inline-block;
        vertical-align: top;

        &:after {
            display: block;
            content: ' ';
            position: relative;
            clear: both;
            height: 1px;
            width: 100%;
        }
    }

    .first-step > .content, .secund-step > .content {
        display: block;
        background: transparent;
        padding: 0;

        img {
            padding: 0;
            display: block;
        }

        p {
            margin-left: 18px;
            max-width: 300px;
            font-weight: 400;
            margin-top: 20px;
            color: #555859;
            text-align: left;
            font-size: 14px;
        }
    }
}

.cell-width {
    min-width: 240px !important;
}

.compare {
    .table > thead > tr > th, .table > tbody > tr > th,
    .table > tfoot > tr > th, .table > thead > tr > td,
    .table > tbody > tr > td, .table > tfoot > tr > td{
        border: 0;
    }
    .loader {
        &-inner {
            background-color: rgba(255, 255, 255, 0.7);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 50;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
                font-size: 30px;
                text-transform: uppercase;
                letter-spacing: 2px;
            }
        }
    }

    .title-row {
        display: flex;
        align-items: center;
        width: 100%;
        background: $light;
        padding: 14px 31px 14px 31px;

        .text {
            .large-title,
            .small-title {
                display: inline-block;
                font-size: 32px;
                font-weight: normal;
                line-height: 140%;
            }
        }

        .close {
            display: none !important;
        }
    }

    .description {
        display: block;
        margin: 0 auto;
    }

    form {
        display: table;

        > label {
            font-size: 14px;
            line-height: 41px;
            padding: 0 10px;
            text-transform: uppercase;
            color: #b5a697;

            position: relative;
            top: 3px;
        }

        > label, > select, > button {
            vertical-align: top;
        }
    }

    .table-responsive {
        overflow: auto;
        ul{
            &[style*="margin-left: 40px"] li,
            &[style*="circle"] li{
                position: relative;
                list-style: none;
                &:before{
                    position: absolute;
                    left: -18px;
                    top: 9px;
                    display: block;
                    width: 6px;
                    height: 6px;
                    border: 1px solid $dark;
                    content: '';
                }
            }
            li{
                ul{
                    li{
                        position: relative;
                        list-style: none;
                        &:before{
                            position: absolute;
                            left: -18px;
                            top: 9px;
                            display: block;
                            width: 6px;
                            height: 6px;
                            border: 1px solid $dark;
                            content: '';
                        }
                    }
                }
            }
        }
    }

    .country-name-wrapper {
        display: inline-block;
    }

    table {
        margin-top: -20px;
        font-size: 12px;
        border: 0;

        tr, td, th {
            background: $light;
            border: 0;
        }

        .country-name{
            font-weight: bold;
        }

        thead > tr > th:nth-of-type(odd) {
            //background-color: rgba(129, 82, 147, 0.11)
        }

        thead > tr > th:first-of-type {
            //background-color: #815293;
        }

        * td {
            position: relative;

            .right {
                float: right;
                top: 0;
                bottom: 0;
            }
        }

        thead > tr > th {
            position: sticky;
            top: 0;
            z-index: 100;
            padding: 0;
            color: $bright;
            font-size: 24px;
            font-weight: bold;
            text-align: center;
            line-height: 28px;
            background: $primary;
            border-bottom: 0;
            vertical-align: middle;

            & > div{
                padding: 15px;
                border-left: 1px solid $darkblue;
                border-right: 1px solid $darkblue;

            }

            &:nth-child(2) > div{
                border-left: 0;
            }

            &:first-child {
                background: $primary-light !important;
            }

            &:nth-child(even),
            &:nth-child(odd) {
                background: $primary;
            }
        }

        tbody > tr > th.level1 {
            font-weight: 300;
            font-size: 22px;
            line-height: 31px;
        }

        tbody > tr:first-child > th.level1 {
            border: 0;
        }

        tr.separator:not(:first-child){
            td{
                height: 5px;
                &:nth-child(even){
                    background: $light;
                }
                &:nth-child(odd){
                    background: $primary-lighter;
                }
            }
        }

        th,
        td{
            width: 313px;
            max-width: 313px;
            min-width: 313px;

            &:nth-child(even){
                background: $primary-lighter;
            }
            &:nth-child(odd){
                background: $light;
            }
        }

        th:first-child,
        td:first-child{
            white-space: normal;
            width: 257px;
            max-width: 340px;
            min-width: 257px;
            background: $primary-light;
            color: $bright;
        }

        th.level2 {
            font-weight: bold;
            font-size: 16px;
            line-height: 23px;
            text-transform: uppercase;
        }

        tbody > tr > th.level3 {
            font-weight: 500;
            font-size: 16px;
            line-height: 23px;
            text-align: right;
            border: 0;
            .pinned-row{
                &:before{
                    position: relative;
                    top: -4px;
                    display: inline-block;
                    margin-right: 5px;
                    content: '';
                    width: 3px;
                    height: 3px;
                    background: $bright;
                }
            }
        }

        tbody > tr > th {
            padding: 0;
            background-color: #fff;
            font-weight: 400;
            color: #4d4d4d;
        }

        tr.bold {
            background-color: $light;
            td{
                vertical-align: bottom;
                p{
                    margin-bottom: 20px;
                }
            }
        }
    }

    tbody > tr {
        border: 0;
        &.bold {
            font-weight: 600;
        };

        font-weight: 400;

        > td {

            > div > p:last-child {
                float: left;
            }

            > div > div:first-child{
                float: left;
            }

            > div > .left{
                text-align: right;
            }
            > div > .right{
                text-align: left;
            }

            .left {
                width: 50%;
                float: left;
            }

            .right {
                width: 50%;
                float: left;
            }

            .left + .right {
                text-align: right;
            }

            .left,
            .right {
                text-align: right;
                padding: 0;
            }

            .collasable {
                min-width: 240px;
                margin: 0 auto;
                display: block;

                img {
                    display: block;
                    margin: 0 auto;
                }
            }

            p {
                padding: 0;
                margin: 12px 20px 12px 20px;
            }

            .openall {
                margin-left: 23px;
                margin-bottom: 8px;
            }

            padding: 0;

            .collasable {
                img {

                    display: block;
                    height: 16px;
                    margin-top: 8px;
                    display: table-cell
                }
            }

            .collasable > div:not(.left):not(.right) {
                width: 50%;
                float: left;

                img {
                    margin: 0 auto;
                }
            }
        }

        td > div > div {
            padding: 13px 0 13px 20px;
        }

        // &:nth-of-type(odd) > {
        //     td:nth-of-type(odd) {
        //         background-color: rgba(129, 82, 147, 0.1);
        //     }

        //     td:nth-of-type(even) {
        //         background-color: rgba(129, 82, 147, 0.22);
        //     }
        // }

        // &:nth-of-type(even) > {
        //     td:nth-of-type(odd) {
        //     }

        //     td:nth-of-type(even) {
        //         background-color: rgba(129, 82, 147, 0.1);
        //     }
        // }
    }

    position: relative;

    .level1{
        .pinned-row {
            position: relative;
            padding: 20px 30px 20px 30px;

            &:before{
                position: absolute;
                top: 26px;
                left: 0;
                display: block;
                width: 17px;
                height: 17px;
                content: '';
                background: $bright;
            }
        }
    }

    .level2{
        .pinned-row{
            padding: 12px 30px 20px 30px;
        }
    }
    .level3{
        .pinned-row{
            padding: 12px 30px 20px 30px;
        }
    }

    .expand-all {
        display: inline-block;
        padding: 0 10px;
        right: 0px;
        line-height: 24px;
        color: #cccccc;
        font-weight: 700;
        text-transform: uppercase;
        z-index: 1000;
        margin: 0 0 0 auto;

        img {
            margin-left: 5px;
            -webkit-transform: translateY(-1px);
            -moz-transform: translateY(-1px);
            -ms-transform: translateY(-1px);
            -o-transform: translateY(-1px);
            transform: translateY(-1px);
        }

        &:hover {
            text-decoration: none;
            background-color: #cccccc;
            color: #ffffff;
        }
    }

    .compare-mobile {
        padding: 20px 15px;

        .table {
            transform: none;

            .table-headline {
                background-color: #f2f2f2;
                padding: 8px;
                font-size: 18px;
                text-transform: uppercase;
                font-weight: 600;
                color: #4d4d4d;
                letter-spacing: 1px;
            }

            tr {
                padding: 15px;
                font-size: 14px;

                td {
                    padding: 5px;
                }
            }
        }

        thead td {
            font-size: 26px;
        }

        tbody > tr td {
            .closeall, .openall {
                margin-left: 23px;
                margin-bottom: 8px;
            }
        }
    }
}

.back-button {
    width: auto;
    background-color: #f0ab00;
    padding: 9px 65px;
    display: inline-block;
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 4px;

    &:hover {
        text-decoration: none;
        color: #ffffff;
        background-color: #0a5174;
    }
}

.print-button {
    width: auto;
    height: 24px;
    padding-left: 30px;
    background: url(/media/images/icon-print.svg) no-repeat left center;
    background-size: 24px auto;
    font-size: 14px;
    color: $black;
    cursor: pointer;
    &:hover{
        color: $black;
    }
}

.meta{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px 19px 16px 41px;
}

.table > tbody {

    border: 0;
}
.select-wrapper {
     position: relative;

     .submit-button-wrapper {
         position: absolute;
         top: 16px;
         right: 0;
         text-align: right;
         padding: 25px 20px;

         @media (max-width: 767px){
             position: relative;
             top: auto;
             right: auto;
             padding-top: 0;
         }

         input {
             display: inline-block;
         }
     }
    .open + .submit-button-wrapper {
        position: relative;
        top: auto;
        right: auto;
        @media (max-width: 767px){
            padding-top: 25px;
        }
    }
}
.details-info{
    margin: 0;
    padding: 17px 31px 18px 31px;
    color: $bright;
    font-size: 18px;
    background: $primary;
}
.select-wrapper.select2{
    .closeDropdown{
        display: block;
        cursor: pointer;
        margin-left: 15px;
        &:before{
            position: relative;
            top: 3px;
            display: block;
            content: '\f329';
            font-family: "Font Awesome 5 Pro";
            font-weight: 400;
            font-size: 22px;
            line-height: 100%;
            -webkit-transition: all 0.3s;
            -moz-transition: all 0.3s;
            -ms-transition: all 0.3s;
            -o-transition: all 0.3s;
            transition: all 0.3s;
            transform: rotate(0);
        }
    }
    .open{
        .closeDropdown {
            &:before {
                transform: rotate(-180deg);
            }
        }
    }
    .title-row{
        background: transparent;
        padding-top: 45px;
        padding-bottom: 45px;
    }
    .selectAll{
        min-width: 104px;
        position: absolute;
        top: 41px;
        right: 20px;
        @media (max-width: 600px){
            display: none;
        }
    }
    .resetDropdown{
        display: none !important;
    }
    .compare-empty{
        display: none !important;
    }
    .dropdown-form {
        .wd-select-footer {
            padding: 0;
        }
        .dropdown-wrapper{
            padding: 23px;
            background: $light;
            -webkit-column-count: 2;
            -moz-column-count: 2;
            column-count: 2;
            -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
            break-inside: avoid;
            -webkit-column-gap: 23px;
            -moz-column-gap: 23px;
            column-gap: 23px;
            display: none;

            @media (max-width: 767px){
                -webkit-column-count: 1;
                -moz-column-count: 1;
                column-count: 1;
            }
        }
        &.open{
            .dropdown-wrapper{
                display: block;
            }
        }
    }
    .dropdown-form ul li{
        .label{
            color: $dark;
        }
        .checkIcon{
            border: 0;
            cursor: pointer;
            &:before,
            &:after{
                width: 17px;
                height: 17px;
                border-radius: 0;
            }
        }
    }

    .group{
        padding-bottom: 10px;
        margin-bottom: 23px;
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
        background: $primary-lighter;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .separator{
        padding: 10px 20px 10px 20px;
        font-size: 24px;
        color: #0071ce;
        background: $primary-lighter;
        cursor: pointer;
        line-height: 140%;
    }
}
.select-wrapper.select1{
    .closeDropdown {
        display: none !important;
    }
    .selectAll{
        min-width: 104px;
        position: absolute;
        top: 10px;
        right: 20px;
        @media (max-width: 600px){
            display: none;
        }
    }
    .resetDropdown{
        display: none !important;
    }
    .dropdown-form{
        .wd-select-footer{
            padding: 0;
        }
        ul li .label{
            margin: 10px;
            padding: 0 18px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            letter-spacing: normal;
            border-radius: 0;
        }
        ul li.active .label{
            margin: 10px;
            font-weight: normal;
            padding: 0 18px;
            background: $primary-darker-50;
            letter-spacing: normal;
            border-radius: 0;
        }
        .group{
            padding: 20px 15px;
        }
        .group-2-col li{
            border: 0;
            width: auto;
            display: inline-block;
        }
    }
    .compare-empty{
        min-height: 60px;
        background: $primary;
        .first-step{
            padding: 17px 28px 17px 31px;
            display: block;
            min-height: 60px;
            background: $primary-darker-50;
        }
    }
    .dropdown-wrapper{
        background: $primary;
        text-align: center;
        border-radius: 0;
    }
    .dropdown-form .separator {
        padding: 14px 31px 14px 31px;
        font-size: 24px;
        background: transparent;
        line-height: 144%;
    }
    .checkIcon{
        display: none;
    }
    .country-labels{
        text-align: right;
        .label-boxes {
            height: 25px;
            padding: 0 10px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color: $bright;
            cursor: pointer;
            line-height: 25px;
            &:after{
                height: 25px;
                content: '\f057';
                font-family: "Font Awesome 5 Pro";
                font-weight: 300;
                font-size: 24px;
                margin-left: 7px;
                vertical-align: middle;
                line-height: 25px;
            }
        }
    }
}


.select2-selection--multiple {
    position: relative;
}

.openall {
    width: 16px !important;
    height: 16px;
    display: block;
    background-color: #b3b3b3;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    line-height: 6px;
    margin-top: 15px;

    &:hover {
        text-decoration: none;
        background-color: #ccaadb;
        -webkit-box-shadow: 0 5px 10px rgba(#000000, 0.75);
        -moz-box-shadow: 0 5px 10px rgba(#000000, 0.75);
        box-shadow: 0 5px 10px rgba(#000000, 0.75);
        color: #000;
    }
}

.closeall {
    width: 16px !important;
    height: 16px;
    display: block;
    background-color: #815293;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    line-height: 6px;
    margin-top: 15px;
    margin-left: 8px;
    margin-bottom: 8px;

    img {

    }

    &:hover {
        text-decoration: none;
        background-color: #ccaadb;
        -webkit-box-shadow: 0 5px 10px rgba(#000000, 0.75);
        -moz-box-shadow: 0 5px 10px rgba(#000000, 0.75);
        box-shadow: 0 5px 10px rgba(#000000, 0.75);
        color: #000;
    }

}

form#compare_form > * {
    vertical-align: middle;
}

@import 'WdSelect';
@media all and (max-width: 767px) {
    .compare-mobile {
        display: block !important;

        table thead td {
            font-size: 22px;
            font-weight: bold;
        }
    }
    .hidden-mobile {
        display: none !important;
    }
    .hidden-desktop {
        display: block !important
    }
}

@media all and (min-width: 768px) {
    .compare-desktop {
        display: block !important;
    }
    .compare-mobile {
        display: none !important;
    }

    .hidden-desktop {
        display: none !important;
    }
    .hidden-mobile {
        display: block;
    }
}

@media print {
    form, .menu {
        padding: 0;
        display: none;
    }

    table {
        border-collapse: separate;
        border: 0 solid #cccccc !important;

    }
    tbody {
        padding: 0;
        border: 2pt solid #cccccc !important;

        tr {
            border: 2pt solid #cccccc !important;

            td {

                padding: 10pt;
                border-color: #cccccc !important;
                border-left: 2pt solid;
                border-bottom: 2pt solid;
            }
        }

    }
    thead {
        font-size: 20pt;
        padding: 10px;
        font-weight: 700;
        border-top: 0pt solid #cccccc !important;
    }
}

@media screen and (max-width: 767px) {
    .table-responsive > .table > thead > tr > th, .table-responsive > .table > tbody > tr > th, .table-responsive > .table > tfoot > tr > th, .table-responsive > .table > thead > tr > td, .table-responsive > .table > tbody > tr > td, .table-responsive > .table > tfoot > tr > td {
        white-space: normal;
    }
}

.line-clamp {
    text-align: left;
    @include line-clamp(5);

    & + .readmore {
        width: 100%;
        text-align: left;
    }
}

.left,
.right{
    .line-clamp {
        text-align: left;
        @include line-clamp(2);

        & + .readmore {
            width: 100%;
            text-align: left;
        }
    }
}

.readmore {
    display: inline-block;
    margin: 0 20px 20px 20px;
    color: $darkblue;
    font-size: 16px;
    font-weight: bold;
    text-decoration: underline;
}

.modal {
    &.in .modal-dialog{
        z-index: 2000;
    }
    &-content {
        min-height: 300px;
        border-radius: 15px;
    }
    &-header {
        border-bottom: 0;
    }
    &-body {
        padding: 0 50px 30px 50px;
        min-height: 245px;
        display: flex;
        align-items: center;

        @media all and (max-width: 768px) {
            padding: 0 15px 30px 15px;
        }
    }
    .close {
        position: absolute;
        right: 5px;
        top: 7px;
        color: $dark;
        opacity: 1;
    }
}
