@mixin keyframeBuilder($name){
    @-webkit-keyframes #{$name} {
        @content; 
    }
    @-moz-keyframes #{$name} {
        @content;
    }
    @-ms-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} { 
        @content;
    }
}
/*egy példa keyframe*/
@include keyframeBuilder(fadeOut){0% { opacity:1; }100% { opacity:.1; }}