@import "./../../scss/general/variables";

.countrypage {
    padding-top: 0;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    flex-direction: row-reverse;
    width: 100%;

    .contact-information {
        order: 2;
        width: 245px;
        padding: 0 0 30px;
        background-color: #f7faff;

        img {
            display: block;

        }
    }

    .country-title {
        letter-spacing: 8px;
        width: 100%;
        color: #4d4d4d;
        display: inline-block;
        margin: 0;
        font-size: 50px;
        height: 36px;
        line-height: 1;
        font-weight: 700;
        padding-left: 15px;
        padding-right: 15px;
    }


    .country-details {
        width: calc(100% - 314px);

        section {
            padding: 0 34px 0 44px;

            @media (max-width: 767.98px){
                padding: 0;
            }
        }

        ul{
            &[style*="margin-left: 40px"] li,
            &[style*="circle"] li{
                position: relative;
                list-style: none;
                &:before{
                    position: absolute;
                    left: -18px;
                    top: 9px;
                    display: block;
                    width: 6px;
                    height: 6px;
                    border: 1px solid $dark;
                    content: '';
                }
            }
            li{
                ul{
                    li{
                        position: relative;
                        list-style: none;
                        &:before{
                            position: absolute;
                            left: -18px;
                            top: 9px;
                            display: block;
                            width: 6px;
                            height: 6px;
                            border: 1px solid $dark;
                            content: '';
                        }
                    }
                }
            }
        }

        .table-wrapper {
            padding: 0;
            background-color: $light;

            > p {
                font-size: 16px;
                padding-left: 15px;
                padding-top: 10px;

            }

            table {
                background-color: #fff;
                outline: 0;
                width: 100%;

                .nobreak, .nobreak * {
                    white-space: nowrap;
                }

                .separator > td {
                    padding-top: 20px;
                    border-top: 1px solid #f0eeeb;
                    border-bottom: 1px solid #f0eeeb;
                    font-weight: 700;

                    &:first-of-type {
                        font-size: 14px;
                    }
                }

                .thead,
                thead {
                    background-color: $primary;
                    height: 25px;
                    line-height: 25px;
                    color: $bright;
                    font-size: 16px;
                    font-weight: 600;
                    text-transform: uppercase;

                    td {
                        padding: 20px 20px 21px 15px;
                        font-size: 16px;
                        color: $bright;
                        text-transform: none;
                        line-height: 130%;

                        &:first-child{
                            padding-left: 43px;
                            padding-right: 0;
                            text-align: left;

                            @media (max-width: 767.98px){
                                padding-left: 15px;
                            }
                        }

                        &:not(:first-child) {
                            text-align: right;
                        }

                        &:last-child {
                            padding-right: 43px;
                        }
                    }
                }

                tbody {
                    tr {
                        background: $table-light;
                        &.primary{
                            background: $table-light;
                            color: $primary;
                        }
                        &.primary-lighter{
                            background: $primary-lighter;
                        }
                        &.bold{
                            font-weight: 600;
                        }
                        &.darker{
                            background: $primary-lighter;
                            font-weight: 600;
                        }
                        td {
                            padding-top: 15px;
                            padding-bottom: 15px;
                            text-align: right;

                            &.separator{
                                background: $primary-lighter;
                                color: $dark;
                            }

                            &:first-of-type {
                                text-align: left;
                                padding-left: 43px;
                                min-width: 140px;
                                width: 260px;
                                @media (max-width: 991.98px){
                                    width: auto;
                                }
                                @media (max-width: 767.98px){
                                    padding-left: 15px;
                                    width: auto;
                                }
                            }

                            &:not(:first-of-type) {
                                padding-left: 20px;
                                padding-right: 20px;

                            }

                            &:last-of-type {
                                padding-right: 43px;
                            }
                        }
                    }
                }
            }

            .wage {

                tr.darker {
                    border-top: 3px solid #b5a697;

                    td:not(:first-of-type) {
                        text-transform: uppercase;
                        font-weight: 600;
                        color: $font-color-menu;
                        font-size: 13px;
                    }

                    td:first-of-type {
                        text-transform: uppercase;
                        font-weight: normal;
                        font-size: 13px;
                    }
                }

                tr:not(:last-of-type) > td {
                    border-bottom: 1px solid #f0eeeb;
                }

                thead {
                    td:not(:first-of-type) {
                        text-align: center;
                        vertical-align: top;
                    }
                }

                tbody {
                    td:not(:first-of-type) {
                        text-align: right;
                        vertical-align: top;
                        padding-left: 10px;
                    }
                }
            }
        }


        > p {
            &:first-of-type {
                margin-top: 20px;
            }

            padding: 0;
            line-height: 15px;
            margin: 5px 0;
            font-size: 12px;
        }

        .progressbar {
            padding: 15px 33px 20px 43px;
            background: $primary;
            color: $bright;

            @media (max-width: 767.98px){
                padding-left: 15px;
                padding-right: 15px;
            }

            p {
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 15px;
            }
        }


        .progress {
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
            margin-bottom: 0;
            background-image: unset;
            background-color: $bright;
            padding: 5px;
            height: 30px;

            p {
                color: #4d4d4d;
                text-transform: uppercase;
            }


            .progress-bar {
                background-color: #B95C2B;
                text-align: left;
                background-image: none;
                width: 100%;
                height: 20px;
                padding: 0 10px;
                font-size: 14px;
            }
        }

        .main-title {
            margin-bottom: 0;
            color: $primary;
            font-size: 55px;
            font-weight: normal;
        }

        .sub-title {
            margin-top: 0;
            font-size: 32px;
            font-weight: normal;
        }
        .table-wrapper{
            margin-bottom: 40px;
            table + .table-details{
                padding-top: 20px;
            }
            .table-details {
                font-size: 14px;
                padding: 0 0 0 43px;
                @media (max-width: 767.98px){
                    padding-left: 15px;
                }
                &:last-child{
                    padding-bottom: 30px;
                    margin-bottom: 0;
                }
            }
        }
    }

    .contact-section {
        width: 314px;

        @media (max-width: 767.98px){
            width: 100%;
        }

        img{
            width: 100%;
            height: auto;
            display: block;
        }

        .contact-box {
            padding: 19px;
            background: $primary-lighter;

            & > *{
                @media (max-width: 767.98px){
                    max-width: 314px;
                    margin: 0 auto;
                }
            }

            *{
                color: $dark;
                font-size: 16px !important;
            }

            &:first-child{
                color: $bright;
                background-color: #004787;
                *{
                    color: $bright;
                    font-weight: normal;
                }
            }

            .contact-content {
                margin-top: 25px;
            }
        }

        .contact-name{
            font-weight: 600;
        }

        .country-name {

        }

        .address{
            margin-bottom: 20px;
        }

        .icon {
            display: none;
        }

        .phone, .link, .mail {
            position: relative;
            padding-left: 25px;

            &:before {
                display: block;
                position: absolute;
                top: 1px;
                left: 0;
                width: 16px !important;
                height: 16px;
                font-size: 16px;
                font-family: "Font Awesome 5 Pro";
                font-weight: 300;
            }

        }

        .fax {
            margin-top: 2px;
            .fax-icon {
                display: inline-block;
                width: 18px;
                height: 18px;
                padding: 0;
                margin: 2px 3px 0 0;
                background-image: url(/media/images/countrypage/fax.svg);
                background-size: contain;
            }
            .fax-icon-white {
                display: inline-block;
                width: 18px;
                height: 18px;
                padding: 0;
                margin: 2px 3px 0 0;
                background-image: url(/media/images/countrypage/fax-white.svg);
                background-size: contain;
            }
        }

        .mail{
            &:before {
                content: "\f0e0";
            }
        }

        .link{
            &:before {
                content: "\f0ac";
            }
        }

        .phone{
            &:before {
                content: "\f095";
            }
        }

        .contact-title{
            position: relative;
            padding-bottom: 30px;
            &:after{
                position: absolute;
                left: 0;
                right: 0;
                bottom: 15px;
                display: block;
                height: 1px;
                background: $gray;
                content: '';
            }
        }

        .detail{
            position: relative;
            padding-left: 15px;
            padding-top: 24px;
            padding-bottom: 18px;

            a{
                display: block;
                text-transform: lowercase;
                font-weight: 600;
            }

            a:first-letter {
                text-transform: uppercase !important;
            }

            &:before {
                display: block;
                content: "\f105";
                position: absolute;
                top: 25px;
                left: 0;
                width: 16px;
                height: 16px;
                font-size: 16px;
                font-family: "Font Awesome 5 Pro";
                font-weight: 300;
            }
        }
    }

}

.fix-header {
    display: none;

}
