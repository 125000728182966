.demo{
  background-image: url(/media/images/demo/demo.jpg);
  padding-top: 280px;
  padding-bottom: 530px;
  background-position: center top;
  iframe{
    display: block;
    overflow-x: hidden;
    margin: 0 auto;
    padding: 0;
    width: 1007px;
  }
}
body{
  margin: 0 !important;
  padding: 0 !important;

}