/* általános beállítások */
* {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
}
:focus{
    outline: none !important;
}
p{
    font-size: 16px;
    line-height: 140%;
    margin-bottom: 25px;
}
a{
    color: $primary;
    outline: 0;
    text-decoration: none;
    @include transition(all,0.2s,ease);
    
    &:hover {
        color: $primary;
        text-decoration: underline;
    }
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
    line-height: 144%;
}

h1, .h1{
    font-size: 32px;
}

ul li{
    list-style: square;
}
.container-fluid{
    padding-left: 21px;
    padding-right: 21px;
}
.container-narrow{
    max-width: 893px;
    margin: 0 auto;
    padding-left: 21px;
    padding-right: 21px;
}
.row{
    margin-left: -21px;
    margin-right: -21px;
    [class*="col-"]{
        padding-left: 21px;
        padding-right: 21px;
    }
}
.button{
    display: inline-block;
    min-width: 170px;
    padding: 13.51px 20px;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    text-align: center;
    border-radius: 0 0 0 20px;
    text-decoration: none;
    text-shadow: none;
    box-shadow: none;
    
    @media (max-width: 479.98px){
        width: 100%;
        display: block;
    }
    
    &:focus,
    &:hover{
        color: $bright;
        text-decoration: none;
    }
    
    .buttons &{
        margin-right: 34px;
        
        @media (max-width: 479.98px){
            margin: 0 0 34px 0;
        }
    }
}
.button-primary{
    border: 1px solid $primary;
    background: $primary;
    color: $bright;
    &:hover{
        border: 1px solid $primary-darker;
        background: $primary-darker;
        color: $bright;
    }
}
.button-black-outline{
    border: 1px solid $black;
    background: transparent;
    color: $black;
    &:focus{
        border: 1px solid $black;
        background: transparent;
        color: $black;
    }
    &:hover{
        border: 1px solid $black;
        background: $black;
        color: $bright;
    }
}


.print {
    width: auto;
    height: 24px;
    padding-left: 30px;
    background: url(/media/images/icon-print.svg) no-repeat left center;
    background-size: 24px auto;
    font-size: 14px;
    color: $black;
    cursor: pointer;
    line-height: 24px;
}

.subpage-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 23px;
    padding: 15px 21px;
    font-size: 32px;
    background: $light;
    line-height: 135%;
    h1{
        margin: 0;
    }
}
