.countrymenu {
    display: none;
    position: relative;
    margin-top: 30px;

    &:after {
        content: ' ';
        display: block;
        width: 100%;
        clear: both;
        position: relative;

    }

    .menu-container {
        width: 100%;
        max-width: calc(1110px - 100px);
        margin-left: 50px;
        margin-right: 50px;
        overflow: hidden;

        .menu-wrapper {
            width: 200%;
            left: 0;
            position: relative;
            -webkit-transition: all 0.2s;
            -moz-transition: all 0.2s;
            -ms-transition: all 0.2s;
            -o-transition: all 0.2s;
            transition: all 0.2s;

            div {
                float: left;
                margin: 2px 5px;
                padding: 5px 10px;
                display: block;
                width: auto;

                &.active {
                    background-color: #b6c7d9;

                    a {
                        color: #ffffff !important;
                    }

                    font-weight: 700;
                }

                a {
                    color: #b6c7d9;
                    font-size: 16px;
                    font-family: DIN, sans-serif;
                    letter-spacing: 2px;
                    font-weight: 400;
                    text-transform: uppercase;
                }
            }
        }
    }

    .navigation-left {
        width: 20px;
        height: 20px;
        left: 15px;

        svg {
        }
    }

    .navigation-right {
        width: 20px;
        height: 20px;
        right: 15px;

        svg {
        }
    }

    .navigation-left, .navigation-right {
        position: absolute;
        top: 7px;
        cursor: pointer;

        -webkit-transition: all 0.2s;
        -moz-transition: all 0.2s;
        -ms-transition: all 0.2s;
        -o-transition: all 0.2s;
        transition: all 0.2s;

        &:hover {
            -webkit-transform: scale(1.3);
            -moz-transform: scale(1.3);
            -ms-transform: scale(1.3);
            -o-transform: scale(1.3);
            transform: scale(1.3);

            svg {
                fill: darken(#b6c7d9, 20) !important
            }
        }
    }
}
