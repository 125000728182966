@import "./../../scss/vendor/flex";
@media screen and (max-width: 767px) {
    //MENU
    .menu {
        nav {
            padding: 21px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            &.clearfix {
                &:before, &:after {
                    content: none;
                    display: none;
                }
            }

            .select-field {
                position: static;
                order: 2;
                float: none;
                height: auto;
                select {
                    margin-top: 0;
                    font-size: 20px;
                }
            }

            .language_selector {
                float: none;
                position: static;
                order: 1;
                align-items: center;
                justify-content: center;
                padding-left: 0;
                a {
                    font-size: 20px;
                }
            }

            ul {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                order: 3;
                width: 100%;
                height: auto;
                margin-top: 21px;

                li {
                    display: inline-block;
                    margin: 0 auto;
                    width: auto;
                    a {
                        font-size: 20px;
                        text-align: center;
                    }
                    &:not(:first-child){
                        a{
                            padding-top: 10px;
                        }
                    }
                }
            }
        }
    }
    //COUNTRYMENU
    .countrymenu {
        display: none;
    }
    //COUNTRYPAGE
    .countrylist {
        .countrypage {
            @include flexbox();
            float: left;
            width: 100%;
            @include flex-direction(column);
            padding-bottom: 60px;

            .contact-information {
                @include order(2);
                margin-top: 30px;
                width: 100%;
                height: 360px;
                @include flexbox();
                align-items: flex-start;
                @include flex-direction(column);
                @include flex-wrap(wrap);
            }

            .country-details {
                @include order(1);
                float: left;
                width: 100%;

                section {
                    .details {
                        width: 100%;
                        clear: both;
                    }
                }

                .table-wrapper {
                    float: none;
                    overflow: auto;
                }
            }
        }
    }
    //SUMMARIZED DATA
    .summarized {
        table {
            display: block;
            overflow-x: scroll;
        }

        .graphs {
            .overview {
                padding: 50px 50px;
            }

            h1, p.summary {
                padding: 0;
            }
        }

        .summery-graph {
            width: 100%;
        }

        .print-button {
            display: none;
        }
    }
}
