.cit-key-features {
    @extend .summarized;
    padding: 0;
    background-color: #fff;

    table {
        width: 100%;
        border: 0;
        thead {
            tr {
                &:first-of-type{
                    & > td{
                        font-size: 12px;
                        text-align: left;
                        line-height: 120%;
                        position: sticky;
                        top: 0;
                        z-index: 100;
                    }
                }
                td {
                    width: auto;
                    min-width: 0;
                    font-size: 12px;
                }
            }
        }

        tbody {
            tr {
                td {
                    text-align: left;
                    font-size: 12px;
                    &:first-of-type {
                        font-weight: 600;
                        max-width: 130px;
                        width: 130px;
                        min-width: 130px;
                        font-size: 15px;
                        text-transform: none;
                        padding-left: 8px;
                    }
                    &:last-child{
                        width: 180px;
                    }
                    & > img{
                        width: 17px;
                        height: auto;
                    }
                }
            }
        }
    }
}
