  #container1{
    .header{
      height: 75px;
      display: -webkit-flex;
      display: flex;
      float: none;
      padding-top: 0;
      -webkit-align-items: center;
      align-items: center;
      background-image: url(/media/images/shadow.png);
      background-repeat: repeat-x;
      background-position: center bottom;
    }
      img{
        margin-left: 10px;
        margin-right: 10px;
      }

      h3{
        font-family: Oswald, sans-serif;
        font-weight: 400;
        font-size: 36px;
        line-height: 45px;
        height: 45px;
        color: #525252;
        margin: 6px 0 0;
        padding: 0;
      }
  }