.introduction{
    margin: 0 auto;
    .intro-box{
        padding: 21px;
        margin-bottom: 60px;
        background: $brand;
        color: $bright;
        .row {
            display: flex;
            flex-wrap: wrap;
        }
        .row > [class*='col-'] {
            display: flex;
        }
        &-text{
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: center;
            h1{
                width: 100%;
                max-width: 320px;
                margin-bottom: 55px;
                color: $bright;
                line-height: 112%;
            }
            p{
                font-size: 14px;
            }
        }
        &-image{
            margin-right: -9px;

            @media (max-width: 479.98px){
                margin-right: auto;
            }

            img{
                width: 100%;
                height: auto;
            }
        }
    }
    .disclaimer{
        margin-top: 70px;
        color: $dark-lighter;
        h2,
        p{
            margin-bottom: 25px;
            font-size: 14px;
            font-weight: normal;
        }
    }
    .buttons{
        padding: 50px 0 20px 0;
    }
    .contact-section {
        &:after{
            display: block;
            content: '';
            clear: both;
        }
    }
    .contact-wrapper {
        display: inline-block;
        margin-top: 20px;
        margin-right: 20px;
        float: left;
        background: $primary-lighter;
        width: 50%;
        max-width: 385px;

        @media (max-width: 830.98px){
            width: 100%;
        }

        @media (max-width: 479.98px){
            margin-right: 0;
        }

        &:last-child{
            margin-right: 0;
        }

        .contact {
            padding: 19px;

            img {
                width: 100%;
                height: auto;
                margin-bottom: 40px;
            }

            a {
                color: $dark;
                &:hover {
                    color: $dark;
                }
            }
        }

        .opener {
            display: none;
        }

        .contact-name {
            display: block;
            margin-bottom: 2px;
            font-size: 16px;
            font-weight: 600;
        }

        .contact-title {
            display: block;
            font-size: 16px;
        }

        .contact-data{
            margin-top: 22px;
            padding-top: 22px;
            border-top: 1px solid $gray;

            .mail, .phone, .link {
                font-size: 16px;
            }

            .detail {
                position: relative;
                margin-top: 25px;
                font-size: 14px;

                i{
                    position: relative;
                    top: 1px;
                    line-height: 16px;
                    font-size: 16px;
                    margin-right: 7px;
                }

                a {
                    span{
                        line-height: 16px;
                        font-weight: 600;
                    }

                    &:hover {

                    }
                }
            }
        }
    }
}