.summarized {
    table {
        width: 100%;
        padding: 10px;
        font-weight: 400;
        border-spacing: 1px;

        * > td {
            padding: 6px;
            vertical-align: middle;
            font-size: 16px;

            &:first-of-type {
                border-left: 0;
            }

            &:last-of-type {
                border-right: 0;
            }
        }

        tr > td:first-of-type {
            padding-left: 38px;
            padding-right: 15px;
            text-align: left;
        }

        * > td {
            text-align: center;
            font-weight: 400;
        }

        tr:not(:first-of-type) {
            text-align: left;
            td {
            }
        }
        tr{
            td:first-child {
                font-weight: 600;
            }
        }

        thead {
            tr {
                &:first-of-type > td {
                    height: 57px;
                    font-weight: normal;
                    background-color: $primary;
                    text-align: center;
                    padding-right: 0;
                    color: $bright;
                    font-size: 24px;
                    vertical-align: middle;
                    &:first-of-type {
                        text-align: left;
                    }
                }

                td {
                    min-width: 175px;
                    outline: 1px;
                    line-height: 20px;
                    font-size: 12px;

                    &:first-of-type{
                        padding-left: 38px;
                    }
                }
            }
        }
    }

    p {
        font-size: 12px;
    }

    > p {
        padding: 40px 30px;
        margin-top: 20px;
        background: #F9F9F9;
    }

    > table + p{
        margin-top: -20px;
    }

    .graphs {

        h1 {
            margin-top: 150px;
            font-weight: normal;
            font-size: 32px;
            text-align: left;
            padding: 0 100px;
            margin-left: 30px;
            margin-right: 30px;
        }

        p.summary {
            font-size: 16px;
            font-weight: 400;
            padding: 0 100px;
            margin: 30px;
            color: #808080;
        }

        img {
            width: 100%;
        }

    }

    .overview {
        color: #ffffff;
        background-color: $brand;
        padding: 130px 100px;
        font-size: 16px;
        font-weight: normal;
        p{
            max-width: 645px;
            margin-left: auto;
            margin-right: auto;
            font-weight: 300;
            font-size: 16px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }

    .summery-graph {
        &:nth-of-type(1) {
            margin-top: 30px;
        }

        margin: 0 auto;
        display: block;;
    }

    .table > thead > tr > th,
    .table > tbody > tr > th,
    .table > tfoot > tr > th,
    .table > thead > tr > td,
    .table > tbody > tr > td,
    .table > tfoot > tr > td{
        border: 0;
    }
    .table > tbody > tr:nth-child(odd) > td{
        background: $light;

        &:first-of-type{
            background-color: $primary-lighter;
            color: $primary;
        }
    }
    .table > tbody > tr:nth-child(even) > td{
        background: #F9F9F9;

        &:first-of-type{
            background-color: $primary-lighter;
            color: $primary;
        }
    }
    tbody{
        border: 0;
    }
}
