/****************************************************
ÁLTALÁNOS BEÁLLÍTÁSOK
*****************************************************/
body {
	color: $dark;
	font-size: 16px;
	font-family: 'HalyardText', sans-serif;
	font-style: normal;
	font-weight: normal;
	line-height: 144%;
	background: $bright;
	-webkit-font-smoothing: antialiased;
}
.wrap { /* középre rendezett oldal esetén wrap */
	margin: 0 auto;
}

/****************************************************
LANGUAGESELECTOR
*****************************************************/
.languageselector {
	float: right;
	padding-top: 20px;
}
.languageselector a {
	display: inline-block;
	width: 16px;
	height: 11px;
	font-size: 0;
	margin-left: 3px;
	background-repeat: no-repeat;
	background-position: top left;
	opacity: .5;
	transition: all .3s;
}
.languageselector a:hover, .languageselector a.active {
	opacity: 1;
}

/****************************************************
LOGO
*****************************************************/
.logo {
	float: left;
}
.logo a, .logo img {
	display: block;
}
.header {
	float: left;
	padding-top: 20px;
}

/****************************************************
SEARCH
*****************************************************/
.search {
	float: right;
	padding-top: 20px;
}
.search button {
	background: #4C689B;
	color: #C5CEDE;
	border: 0;
	font-weight: bold;
	cursor: pointer;
	height: 21px;
	transition: all .3s;
}
.search button:hover {
	background: #203A64;
	color: #FFF;
}

/****************************************************
MENÜ
*****************************************************/
/* minden szintre vonatkozó beállítások */
.menu nav {
	font-size: 14px;
	font-weight: bold;
	background: #4c689b;
	z-index: 50;
	position: relative;
}
.menu nav ul {
	list-style: none;
	padding: 0;
	margin: 0;
	display: block;
}
.menu nav a {
	display: block;
	text-decoration: none;
}
.menu nav li {
	position: relative;
	padding: 0;
	margin: 0;
	display: block;
}
.menu nav ul ul {
	position: absolute;
	opacity: 0;
	filter: alpha(opacity=0);
	visibility: hidden;
	transition: all .5s ease .5s;
}
/* általános megnyílás hoverre */
.menu nav li:hover > ul {
	z-index: 52;
	opacity: 1;
	filter: alpha(opacity=100);
	transition-delay: 0s;
	visibility: visible;
}
/* 1. szint */
.menu nav > ul {
	z-index: 51;
	position: relative;
}
.menu nav > ul > li {
	float: left;
}
.menu nav > ul > li > a {
	height: 30px;
	line-height: 30px;
}
/* 2. szint */
.menu nav > ul > li > ul {
	top: 30px;
	left: 0;
	min-width: 100%;
}
.menu nav > ul > li > ul > li > a {
	white-space: nowrap;
}
/* egyéb beállítások csak hogy értelmes legyen a megjelenés */
.menu nav li a {
	background: #4c689b;
	color: #c5cede;
	padding: 0 10px;
	transition: all .3s;
}
.menu nav li li a {
	background: #4c689b;
	color: #c5cede;
	padding: 0 5px;
	border-right: 0;
}
/* hover hatások */
.menu nav li:hover > a {
	background: #203a64;
	color: #fff;
}
.menu nav li li:hover > a {
	background: #4c689b;
	color: #fff;
}
/* IE hack. Külön css-ben kellene tárolni, mert itt nem szép. */
.menu nav ul ul {
	visibility: visible\9;
	display: none\9;
	*width: 160px;
}
.menu nav li:hover > ul {
	display: block\9;
}
.menu nav > ul > li > ul > li > a {
	*width: 100%;
	*white-space: normal;
}
/****************************************************
BODY
*****************************************************/
.body a {
	color: #4C689B;
}
.body a:hover {
	text-decoration: underline;
}

/****************************************************
SLIDER
*****************************************************/
#slider {
	box-shadow: 0 0 0 #000;
	width: 100%;
}

/****************************************************
NEWS
*****************************************************/
.news h1 {
	color: #303233;
	font-size: 20px;
	font-weight: bold;
}
.news h2 {
	color: #4C5052;
	font-size: 16px;
	font-weight: bold;
	position: relative;
	left: -1%;
}

/* nyitólapon */
.news article {
	float: left;
	background: white;
	margin: 10px 2% 10px 0;
	width: 46%;
	padding: 1%;
	line-height: 1.5em;
}
.news article:not(article) {
	text-align: justify;
}
.news section > article:last-child {
	margin: 10px 0 10px 2%;
}
.news article .date {
	color: #6584B5;
	font-size: .9em;
}
.news article img {
	float: left;
	width: 30%;
	margin-right: 10px;
}
.news article .more {
	float: right;
	font-size: 1.2em;
}
.news article a {
	color: #5474A8;
}

/* hírek oldalon */
.news .box{
	overflow: auto;
}
.news .box > *:not(h2) {
	padding-left: 4px;
	line-height: 1.5em;
}
.news .box h2 {
	left: 0;
	padding: 2px;
}
.news .box .date {
	color: #8D9DB5;
	font-style: italic;
	font-size: .8em;

}
.news .box .news_content {
	padding: 3px 8px;
	text-align: justify;
}
.news .box .newsimg {
	float: left;
	padding-right: 8px;
}
.news .box .newsimg img{
	display: block;
}
.news .box .more {
	font-size: 1.2em;
}

.news .news_one_version{
	text-align: justify;
}
.news .news_one_version h2{
	padding-bottom: 5px;
	padding-top: 5px;
}
.news .news_one_version ul{
	margin-left: 20px;
}


/****************************************************
BREADCRUMB
*****************************************************/
.breadcrumb {
	padding: 10px 0;
}


/****************************************************
CONTENT
*****************************************************/
.content {
	padding: 0;
	background: #fff;
}
.content li {
	list-style: square;
	margin-left: 20px;
}

/****************************************************
NEWSLETTER
*****************************************************/
.newsletter {
	padding: 20px;
	background: #fff;
}
.newsletter .item.error {
	display: none;
	color: #F00;
}
.newsletter .item.success {
	display: none;
	color: #0F0;
}
.newsletter label {
	float: left;
	width: 150px;
	text-align: right;
	padding-right: 10px;
	min-height: 10px;
}
.newsletter .field {
	float: left;
	width: 200px;
}
.newsletter .item {
	margin-bottom: 10px;
}
/*.content h1 {
	color: #8B8F90;
	font-size: 18px;
	font-weight: bold;
}
.content h2 {
	color: #8B8F90;
	font-size: 14px;
	font-weight: bold;
}*/


/****************************************************
FOOTER
*****************************************************/
.footer {
	background: #C3CDE1;
	padding: 20px 0;
	margin-top: 20px;
}
.footer hr {
	color: #c5cede;
}
.footer .sitemap_links > ul {
	float: left;
	margin: 0 3%;
	width: 26%;
}
.footer .sitemap_links > ul {
	line-height: 1.5em;
}

.footer a {
	color: #4C689B;
}
.footer a:hover {
	text-decoration: underline;
}
.footer hr {
	background: #4C689B;
}
.footer .address{
	text-align: center;
}
/****************************************************
DOWNLOADS
*****************************************************/
.body_downloads td,
.body_downloads th {
	padding: 5px;
}
.body_downloads .title {
	width: 50%;
}

.content .history,
.downloads .history,
.news .history {
	font-size: 10px;
	margin-top: 20px;
}