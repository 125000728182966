@media screen and (max-width: 480px){
  //SUMMARIZED DATA
  .summarized{
    .graphs{
      .overview{
        padding: 50px 30px;
      }
      p.summary{
        padding:0px 0px;
      }
    }
  }
}