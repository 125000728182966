$main-page-bg-color: #ffffff;
$countrylist-bg-color: #f6faff;
$font-color-title: #4d4d4d ;
$font-color: #464B4B;
$color-gray: #f2f2f2;
$font-color-menu: #000036;

$brand: #90344D;

$primary: #0071CE;
$primary-darker: #081F8F;
$primary-darker-50: rgba(8, 31, 143, 0.5);
$primary-lighter: #E5F0FA;
$primary-light: #4C88D2;

$dark: #464646;
$dark-lighter: #787878;

$bright: #ffffff;

$light: #F4F4F4;

$gray: #C4C4C4;
$black: #000000;
$darkblue: #0448AE;

$table-light: #F7F3F4;