.countrylist {

    overflow: hidden;
    
    @media (max-width: 767.98px){
        margin: 0;
    }
    
    .countrylist-wrapper{
        display: flex;
        flex-wrap: wrap;
        margin: 0 9px;
    
        @media (max-width: 767.98px){
            margin-left: -12px;
            margin-right: -12px;
        }
    }
    
    .print_header {
        display: none;
    }
    
    .list_div {
        display: block;
        width: 20%;
        padding: 0 12px;
        margin-bottom: 24px;
        cursor: pointer;
        
        @media (max-width: 991.98px){
            width: 25%;
        }
        
        @media (max-width: 767.98px){
            width: 33.33%;
        }
        
        @media (max-width: 562.98px){
            width: 50%;
        }
        
        .list-item {
            .countryname {
                text-align: left;
                position: relative;
                overflow: hidden;
                background-color: $light;
                padding: 20px 20px 0 20px;
                @include flexbox();
                @include align-items(center);
                @include justify-content(center);
                @include flex-direction(column);
                -webkit-transition: .2s;
                -moz-transition: .2s;
                -ms-transition: .2s;
                -o-transition: .2s;
                transition: .2s;
                
                &:hover{
                    text-decoration: none;
                }
                
                span {
                    position: relative;
                    display: flex;
                    width: 100%;
                    height: 47px;
                    margin: 6px 0 7px 0;
                    align-items: center;
                    text-align: left;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 100%;
                    color: $dark;
                }
                
                &:hover {
                    background-color: $primary;
                    
                    span {
                        color: $bright;
                    }
                    
                    img {
                        border: none;
                    }
                }
                
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.button-wrapper{
    display: flex;
    height: 73px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 23px;
    padding: 15px 21px;
    background: $light;
    
    .back{
        position: relative;
        display: block;
        padding-left: 18px;
        color: $black;
        font-size: 14px;
        cursor: pointer;
        &:before{
            display: block;
            content: "\f104";
            position: absolute;
            top: 2px;
            left: 0;
            width: 16px;
            height: 30px;
            color: $black;
            font-size: 30px;
            font-family: "Font Awesome 5 Pro";
            font-weight: 300;
        }
    }
}


