.menu{
    min-height: 62px;
    nav {
        display: flex;
        align-items: center;
        padding: 0 21px;
        background-color: transparent;
        
        ul {
            display: flex;
            flex-grow: 1;
            height: 80px;
            justify-content: flex-start;
            align-items: center;
            
            li {
                margin: 0 1.5%;
                background: transparent;
                &:hover{
                    background: transparent;
                    a{
                        color: $dark;
                        background: transparent;
                    }
                }
                a {
                    height: auto;
                    padding: 2px 4px;
                    color: $dark;
                    font-size: 18px;
                    font-weight: normal;
                    line-height: 110%;
                    text-align: center;
                    background: transparent;
                    border-bottom: 1px solid transparent;
                    border-top: 1px solid transparent;
                    &.active,
                    &:hover{
                        background-color: transparent;
                        color: $dark;
                        border-bottom-color: $primary;
                    }
                }
            }
        }
        
        .language_selector{
            display: flex;
            padding: 0 15px;
            a{
                position: relative;
                display: inline-block;
                color: $dark;
                font-size: 18px;
                font-weight: normal;
                text-transform: uppercase;
                line-height: 25px;
                
                &.active{
                    font-weight: 600;
                }
                
                &:not(:last-child){
                    padding-right: 20px;
                    &:after{
                        position: absolute;
                        top: 0;
                        right: 0;
                        display: inline-block;
                        width: 1px;
                        height: 25px;
                        margin: 0 10px;
                        content: '';
                        background: $dark;
                    }
                }
            }
        }
        
        .select-field{
            select{
                width: 104px;
                height: 40px;
                padding: 0 40px 0 10px;
                font-weight: normal;
                font-size: 18px;
                line-height: 38px;
                border: 1px solid $gray;
                border-radius: 0;
                background: url(../images/icon-arrow-down.svg) no-repeat right 14px center;
                background-size: 15px auto;
                appearance: none;
                outline: none;
            }
    
            select::-ms-expand {
                display: none;
            }
        }
    }
}

.menu-opener {
    @include transition(all);
    
    margin-top: 15px;
    $border-width: 3;
    width: #{$border-width + 30}px;
    height: #{$border-width + 30}px;
    position: relative;
    float: right;
    padding-left: 4px;
    padding-top: 5px;
    border: #{$border-width}px solid $primary;
    display: none;
    
    top: 0;
    left: 0;
    
    > span {
        @include transition(all);
        
        display: block;
        height: 3px;
        width: 20px;
        background-color: $primary;
        margin-bottom: 4px;
    }
    
    &.opened {
        margin-bottom: -6px;
        width: #{$border-width + 36}px;
        height: #{$border-width + 36}px;
        
        left: 3px;
        top: -3px;
        
        padding-top: 7px;
        
        > span {
            display: block;
            height: 3px;
            width: 25px;
            background-color: $primary;
            margin-bottom: 5px;
        }
    }
}