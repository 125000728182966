$iconSize: 14px;
$iconColor: #cccccc;
$iconColor-hover: #815293;
$openerBorber: #aaaaaa;
$openerFont: sans-serif;

#compare_form {
    display: block;
    width: 100%;
}


.select-wrapper {
    width: 100%;
    select, input {
        display: none;
    }

    * {
        -webkit-user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .selectOpener {
        height: 60px;
        line-height: 36px;
        padding-left: 15px;
        padding-right: 15px;
        border-left: 1px solid #000036;
        border-right: 1px solid #000036;
        font-family: $openerFont;
        background-color: #fff;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
        margin-right: 5px;
        z-index: 1;
        display: none !important;

        &:hover {
            background-color: #f0ab00;

            .title-row {
                .close {
                    border-color: #fff;

                    &:before {
                        background-color: #fff;
                    }

                    &:after {
                        background-color: #fff;
                    }
                }
            }
        }

        .label-boxes {
            background-color: #e4e4e4;
            border: 1px solid #aaa;
            border-radius: 4px;
            cursor: default;
            margin-right: 5px;
            margin-top: 12px;
            padding: 4px 5px 2px;
            line-height: 20px;
            height: 20px;
            white-space: nowrap;
            font-family: 'Oswald', sans-serif;
            display: inline;
        }

    }

    /*.dropdown-form{
        display: none;
        ul{
            -webkit-box-shadow: 0 10px 50px rgba(#000000, 0.5);
            -moz-box-shadow: 0 10px 50px rgba(#000000, 0.5);
            box-shadow: 0 10px 50px rgba(#000000, 0.5);
            background-color: #fff;
            position: absolute;
            right: 0;
            display: block;
            list-style: none;
            margin: 0;
            padding: 15px;

            .separator{
                text-align: left;
                font-family: 'Oswald', sans-serif;
                font-weight: 700;
                color: #b5a697;
                font-size: 16px;
                text-transform: uppercase;
                cursor: pointer !important;
            }
            li{
                position: relative;

                &.hovered:not(.active){
                    &:after{
                        content: ' ';
                        display: block;
                        position: absolute;

                        left: 0;
                        right: 2px;
                        top: 0;
                        bottom: 0;
                        border: 2px dashed #d3d5d7;
                        -webkit-border-radius: 5px;
                        -moz-border-radius: 5px;
                        border-radius: 5px;
                    }
                .checkIcon{
                    background-color: #be80d2;

                }
                }


                width: 210px;
                padding: 0;
                margin: 5px 0;
                position: relative;
                cursor: pointer;

                .checkIcon{
                    display: block;
                    position: absolute;
                    width: $iconSize;
                    height: $iconSize;
                    background-color: $iconColor;
                    left: 15px;
                    border-radius: 7px;
                    top: 10px;
                    -webkit-transition: all 0.3s;
                    -moz-transition: all 0.3s;
                    -ms-transition: all 0.3s;
                    -o-transition: all 0.3s;
                    transition: all 0.3s;
                }

                .label{
                    width: 210px;
                    display: inline-block;
                    box-sizing: border-box;
                    padding: 5px 15px 5px 45px;
                    text-align: left;
                    font-family: 'Oswald', sans-serif;
                    font-weight: 700;
                    color: #525252;
                    font-size: 15px;
                    line-height: 20px;
                    white-space: normal;
                    padding-top: 10px;
                }

                &.active{
                    .checkIcon{
                        background-color: $iconColor-hover;
                        animation: pop 0.3s;
                    }
                    .label{
                        background-color: #f1f1f1;
                    }
                }
            }
            
            .selectAll{
                text-align: center;
                background-color: $iconColor-hover;
                color: #ffffff;
                line-height: 25px;
                font-size: 15px;
            }
        }
    }*/

    @keyframes animate-dropdown-in {
        0% {
            transform: scale(1, 0);
        }
        100% {
            transform: scale(1, 1);
        }
    }
    @keyframes animate-dropdown-out {
        0% {
            transform: scale(1, 1);
        }
        100% {
            transform: scale(1, 0);
        }
    }

    .dropdown-form {
        
        .dropdown-wrapper {
        
        }

        ul {
            background-color: #fff;
            margin: 0;
            padding: 0;
            display: flex;
            color: #808080;
            list-style: none;
            flex-wrap: wrap;
            li {
                list-style: none;
                margin: 0;
                padding: 0;
                .list-item {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    position: relative;
                }

                .checkIcon {
                    padding-left: 15px;
                    padding-right: 15px;
                    width: 40px;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    border-right: 2px solid #f2f2f2;

                    &:before {
                        position: absolute;
                        width: 20px;
                        height: 20px;
                        -webkit-border-radius: 50%;
                        -moz-border-radius: 50%;
                        border-radius: 50%;
                        background-color: $primary;
                        content: '';
                        left: 20px;
                        display: block;
                        top: 8px;
                    }

                    &:after {
                        position: absolute;
                        width: 22px;
                        height: 22px;
                        -webkit-border-radius: 50%;
                        -moz-border-radius: 50%;
                        border-radius: 50%;
                        background-color: $bright;
                        content: '';
                        left: 20px;
                        display: block;
                        top: 8px;
                        z-index: 3;
                    }
                }

                .label {
                    padding-left: 50px;
                    line-height: 144%;
                    text-align: left;
                    white-space: normal;
                    font-size: 16px;
                    padding-top: 6px;
                    padding-bottom: 6px;
                    display: block;
                    min-height: 30px;
                    font-weight: 300;
                    cursor: pointer;
                }

                &.active {
                    .checkIcon {
                        &:after {
                            transform: scale(0);
                        }

                        &:before {
                            background-color: $primary;
                        }
                    }
                }

                &:hover, &.hovered {
                    .checkIcon {
                        &:before {
                            background-color: $primary;
                        }
                    }
                }
            }
        }

        .group {
            &-2-col {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                border-right: 0;
                .separator {
                }

                li {
                    border-right: 4px solid #f2f2f2;
                    width: 50%;
                }
            }
        }

        li.active {

        }
        .wd-select-footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 15px 15px 15px;
            .selectAll {

            }
            .resetDropdown {
                width: auto;
                background-color: #000036;
                padding: 9px 65px;
                display: inline-block;
                color: #fff;
                font-size: 18px;
                text-transform: uppercase;
                font-weight: 400;
                font-family: DIN, sans-serif;
                letter-spacing: 4px;
                &:hover {
                    background-color: lighten(#000036, 10);
                }
            }
        }
    }

    .dropdown-form.open {
        display: block;
    }

    .dropdown-form.close {
        pointer-events: none !important;
        opacity: 0;
    }

    .wdSelectOverlay {
        position: relative;
    }
}
